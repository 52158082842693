import React, { useState, useEffect, useContext, Fragment, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import useApi from 'hooks/useApi'
import ErrorsContext from "ErrorsContext";
import ExternalLink from "../../shared/ExternalLink";
import Modal from 'shared/Modal'
import useInterval from '../../hooks/useInterval'
import Loading from 'shared/Loading'
import FormatNumber from 'shared/FormatNumber'
import useDebouncedCallback from 'utils/useDeboucedCallback'




import './LicenseVerificationPage.scss'
import toast from 'react-hot-toast'
import { times } from 'lodash';


export default function LicenseVerificationPage() {
    const [licenseVerificationList, setLicenseVerificationList] = useState(null)
    const { setError } = useContext(ErrorsContext)
    const { apiPost, apiGet } = useApi()
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [ totalCount, setTotalCount ] = useState(null)
    const [currentShowingCount, setCurrentShowingCount] = useState(0) 
    const [ page, setPage ] = useState(0)
    const [ hasMore, setHasMore] = useState(true)
    const [search, setSearch] = useState('')
    const [orderStatusFilter, setOrderStatusFilter] = useState('')
    const [itemStatusFilter, setItemStatusFilter] = useState('')
    const [categoryFilter, setCategoryFilter] = useState('')




    const [licenseModalIndex, setLicenseModalIndex] = useState()
    const [refreshLicenseVerificationTable, setRefreshLicenseVerificationTable] = useState(true)
    const [ licenseRefreshTime, setLicenseRefreshTime ] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ sortBy, setSortBy ] = useState(['updated_at', 'desc'])
    const [ queryTrigger, setQueryTrigger ] = useState(0) // used to allow query changes to be debounced

    const categoryFilterOptions = [
        {'displayName': 'Home Services', 'param': 'homeServices'}, {'displayName': 'Dentistry', 'param': 'dentistry'}
    ]

    const itemStatusFilterOptions = [
        {'displayName': 'Not Found', 'param': 'notFound'}, {'displayName': 'Adjudicated', 'param': 'adjudicated'}
    ]

    const orderStatusFilterOptions = [
        {'displayName': 'Monitoring', 'param': 'monitoring'}, {'displayName': 'Requested', 'param': 'requested'}, 
        {'displayName': 'Awaiting Inventory', 'param': 'awaitingInventory'}, {'displayName': 'Complete', 'param': 'complete'}
    ]





    useEffect(() => {
        setLoading(true)
        const [currentCol, currentDir] = sortBy

        apiGet(`lender/license-verification-orders`, 
        {
            sortBy: (currentDir==="desc" ? `-${currentCol}` : `${currentCol}`),
            search: search,
            category: categoryFilter,
            itemStatus: itemStatusFilter,
            orderStatus: orderStatusFilter
        }).then(results => {
            if (results){
                setLicenseVerificationList(results)
                setTotalCount(results.count)
                // setCurrentShowingCount(results.results.length)
                // if (currentShowingCount === totalCount) {
                //     setHasMore(false)
                // } else {
                //     setHasMore(true)
                // }
            }

        }).catch( response => {
            console.log(response)
        })
        setLoading(false)
    }, [sortBy, search, categoryFilter, itemStatusFilter, queryTrigger, orderStatusFilter])


    useEffect(() => {
        setLoading(true)
        setCurrentShowingCount(licenseVerificationList?.results.length)
        if (licenseVerificationList?.results.length === totalCount) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
        setLoading(false)
    }, [totalCount, licenseVerificationList])

    // useInterval(async () => {
    //     apiGet(`lender/license-verification-orders`).then(results => {
    //         if (results){
    //             setLicenseVerificationList(results)
    //         }
    //     }).catch( response => {
    //         console.log(response)
    //     }).finally(() => {
            
    //     });
    // }, licenseRefreshTime)

    

    const debouncedSetQuery = useDebouncedCallback(500,
        () => {
            setQueryTrigger(queryTrigger + 1)
        }, [setQueryTrigger, queryTrigger])


    function handleSeeMore() {
        setLoading(true)
        if (licenseVerificationList && licenseVerificationList.next) {
            apiGet(licenseVerificationList.next.split('v1/')[1])
                .then(response => {
                    if (response) {
                        // Concatenate the existing results with the new results
                        setLicenseVerificationList(prevState => ({
                            ...response,
                            results: [...prevState.results, ...response.results],
                        }));
                        setCurrentShowingCount(licenseVerificationList.results.length + response.results.length)
                        if (currentShowingCount === totalCount) {
                            setHasMore(false)
                        }else {
                            setHasMore(true)
                        }
                    }
                })
                .catch(response => {
                    console.log(response);
                });
        }
        setLoading(false)
        // setQueryTrigger(queryTrigger + 1)
    }

    const onClickSortBy = (sortCol) => {
        setLoading(true)
        const [currentCol, currentDir] = sortBy
        if (sortCol !== currentCol) {
            // switch to requested column, ascending
            setSortBy([sortCol, 'asc'])
        } else {
            if (currentDir === 'asc') {
                // same column, switch to descending
                setSortBy([ sortCol, 'desc'])
            } else {
                // same column, switch to acending
                setSortBy([ sortCol, 'asc'])
            }
        }
        setLoading(false)
    }




    function formattedDate(isoDate) {
        const dateObj = new Date(isoDate);
        const year = dateObj.getFullYear();
        const month = `0${dateObj.getMonth() + 1}`.slice(-2); // Months are zero-indexed, so January is 0
        const day = `0${dateObj.getDate()}`.slice(-2);
        const hours = `0${dateObj.getHours()}`.slice(-2);
        const minutes = `0${dateObj.getMinutes()}`.slice(-2);
        const seconds = `0${dateObj.getSeconds()}`.slice(-2);
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    function camelCaseToNormal(camelCaseString) {
        if(!!camelCaseString === false){
            return camelCaseString; // Return if input is null
        }
        const words = camelCaseString.match(/(?:^[a-z]+|[A-Z][a-z]+)/g);
        if (!words) {
            return camelCaseString; // Return empty string if no valid words found
        }
        const spacedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return spacedString;
        
    }

    function snakeCaseToNormal(snakeCaseString) {
        if (snakeCaseString == null) {
            return snakeCaseString; // Return if input is null or undefined
        }
    
        // Check if the string is actually in snake_case
        if (!/^[a-z]+(_[a-z]+)*$/.test(snakeCaseString)) {
            return snakeCaseString; // Return original string if it's not valid snake_case
        }
    
        // Split the string by underscores, capitalize each word, and join with spaces
        const words = snakeCaseString.split('_');
        const normalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return normalizedWords.join(' ');
    }

    function formatLabelLists(labels) {
        // console.log('heres labels before?: ', labels)
        if (labels.length === 0) {
            return labels
        } else {
            let formattedList = labels.map(snakeCaseToNormal)
            return formattedList
        }
    }

    const sortDirFor = (col) => sortBy && sortBy[0] === col && sortBy[1]

    return (
        <div className="main-container">
            <h1>License Requests</h1>
            <div className="filter-container">
                <label htmlFor='search'><i className='fa fa-search' /></label>
                    <input type='search' style={{ width: '250px', marginRight: '50px'}} placeholder='Search License Requests' id='search'
                        value={search} onChange={ev => { setSearch(ev.target.value); debouncedSetQuery() }} />

                <select value={categoryFilter} style={{ marginRight: '3px'}} onChange={(event) => setCategoryFilter(event.target.value)}>
                    <option value="">All Categories</option>
                    {categoryFilterOptions.map((option) => (
                        <option key={option.param} value={option.param}>
                        {option.displayName}
                        </option>
                    ))}
                </select>
                <select value={orderStatusFilter} style={{ marginRight: '3px'}} onChange={(event) => setOrderStatusFilter(event.target.value)}>
                    <option value="">All Order Statuses</option>
                    {orderStatusFilterOptions.map((option) => (
                        <option key={option.param} value={option.param}>
                        {option.displayName}
                        </option>
                    ))}
                </select>
                <select value={itemStatusFilter} onChange={(event) => setItemStatusFilter(event.target.value)}>
                    <option value="">All Item Statuses</option>
                    {itemStatusFilterOptions.map((option) => (
                        <option key={option.param} value={option.param}>
                        {option.displayName}
                        </option>
                    ))}
                </select>

            </div>
            <div className="license-form">
            {loading && <Loading />}

                {!!licenseVerificationList && !loading && licenseVerificationList.results.length > 0 ? (
                    <table className="my-table">
                    <thead>
                        <tr>
                        <th onClick={() => onClickSortBy('name')}>
                        <Sorter dir={sortDirFor('name')} />
                            Business Name
                            </th>
                        <th onClick={() => onClickSortBy('licensee_name')}>
                        <Sorter dir={sortDirFor('licensee_name')} />
                            Licensee Name</th>
                        <th onClick={() => onClickSortBy('licensee_id')}>
                        <Sorter dir={sortDirFor('licensee_id')} />
                            Licensee ID</th>
                        <th onClick={() => onClickSortBy('licensee_category')}>
                        <Sorter dir={sortDirFor('licensee_category')} />
                            Category</th>
                        <th onClick={() => onClickSortBy('mesh_order_status')}>
                        <Sorter dir={sortDirFor('mesh_order_status')} />
                            Order Status</th>
                        <th onClick={() => onClickSortBy('mesh_item_status')}>
                        <Sorter dir={sortDirFor('mesh_item_status')} />
                            Item Status</th>
                        <th onClick={() => onClickSortBy('updated_at')}>
                        <Sorter dir={sortDirFor('updated_at')} />
                            Updated At</th>
                        <th>Details</th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {licenseVerificationList.results.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.licensee_name}</td>
                            <td>{item.licensee_id}</td>
                            <td>{camelCaseToNormal(item.licensee_category)}</td>
                            <td>{camelCaseToNormal(item.mesh_order_status)}</td>
                            <td>{camelCaseToNormal(item.mesh_item_status)}</td>
                            <td>{formattedDate(item.updated_at)}</td>
                            <td>
                                <button className='note-button' onClick={() => {setShowDetailsModal(true); setLicenseModalIndex(index)}}>
                                    <i className="fa fa-sticky-note-o"/>
                                </button>
                            </td>
                            <td>
                            <Link to={`/healthcheck/overview/merchant/${item.merchant}`} className='button primary' target='_blank'>Run Report</Link>
                        </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                    
                ) : (<p>No License Verification History</p>)}

                <div className="license-modal">
                {showDetailsModal &&
                    <Modal>
                    <div className='license-header-button'>
                        <h1>Verification Details</h1>
                        <button onClick={() => setShowDetailsModal(false)}>Close</button>
                    </div>
                    <div className='license-verification-data'>
                    <div className='data-column'>
                        <h2>Input</h2>
                        <span>Licensee Name: {licenseVerificationList.results[licenseModalIndex].licensee_name} </span>
                        <span>License ID: {licenseVerificationList.results[licenseModalIndex].licensee_id} </span>
                        <span>License Category: {camelCaseToNormal(licenseVerificationList.results[licenseModalIndex].licensee_category)} </span>
                        <span>License Subcategory: {camelCaseToNormal(licenseVerificationList.results[licenseModalIndex].licensee_subcategory)} </span>
                        <span>License Address: {licenseVerificationList.results[licenseModalIndex].licensee_addr} </span>
                        <span>License State: {licenseVerificationList.results[licenseModalIndex].licensee_state} </span>
                        <span>License City: {licenseVerificationList.results[licenseModalIndex].licensee_city} </span>
                        <span>License Zip: {licenseVerificationList.results[licenseModalIndex].licensee_zip5} </span>
                    </div>
                    <div className='data-column'>
                        <h2>Verified</h2>
                        <span>Licensee Name: {licenseVerificationList.results[licenseModalIndex].mesh_licensee_name} </span>
                        <span>License ID: {licenseVerificationList.results[licenseModalIndex].mesh_licensee_number} </span>
                        <span>License Category: {camelCaseToNormal(licenseVerificationList.results[licenseModalIndex].mesh_licensee_category)} </span>
                        <span>License Type As Is: {licenseVerificationList.results[licenseModalIndex].mesh_licensee_type_asis} </span>
                        {/* <span>Issuing Authority: {licenseVerificationList.results[licenseModalIndex].mesh_issuing_authority} </span> */}
                        <span>License Authority Board: {licenseVerificationList.results[licenseModalIndex].mesh_license_authority_board} </span>
                        <span>License Status: {licenseVerificationList.results[licenseModalIndex].mesh_license_status} </span>
                        <span>License Status As Is: {licenseVerificationList.results[licenseModalIndex].mesh_license_status_asis} </span>
                        <span>License Exp Date: {licenseVerificationList.results[licenseModalIndex].mesh_license_exp_date} </span>
                        <span>License Issue Date: {licenseVerificationList.results[licenseModalIndex].mesh_license_issue_date} </span>


                    </div>
                    </div>
                    <div className='metadata-column'>
                    <span >Created: {formattedDate(licenseVerificationList.results[licenseModalIndex].created_at)} </span>
                    <span className='bRight'>Order Status: {camelCaseToNormal(licenseVerificationList.results[licenseModalIndex].mesh_order_status)} </span><br></br>
                    <span>Last Updated: {formattedDate(licenseVerificationList.results[licenseModalIndex].updated_at)} </span>
                    <span className='bRight'>Order Labels: {licenseVerificationList.results[licenseModalIndex].mesh_order_labels ? (formatLabelLists(licenseVerificationList.results[licenseModalIndex].mesh_order_labels)).join(', ') : ''}</span><br></br>
                    <span className='bRight'>Item Status: {camelCaseToNormal(licenseVerificationList.results[licenseModalIndex].mesh_item_status)}</span><br></br>
                    <span className='bRight'>Item Labels: {licenseVerificationList.results[licenseModalIndex].mesh_item_labels ? (formatLabelLists(licenseVerificationList.results[licenseModalIndex].mesh_item_labels)).join(', ') : ''}</span><br></br>

                    </div>
                </Modal>}
                </div>
            </div>
            <div className="show-more-container">
            {!!licenseVerificationList && licenseVerificationList.next && !loading &&
            (   
                <>
                <br></br>
                <button className="see-more-button" onClick={()=> handleSeeMore()}>See More Results</button> 
                </>
                

            )
            }
            {loading && <Loading />}
                {(!loading) && (
                    <div className='pager-more'>
                        {hasMore && licenseVerificationList && licenseVerificationList.results.length > 0 && <>
                            <p>Showing <FormatNumber value={currentShowingCount} /> License Orders of <FormatNumber value={totalCount} /></p>
                            {/* <button className='load-more' onClick={handleSeeMore}>Show More</button> */}
                        </>}
                        {!hasMore && licenseVerificationList && licenseVerificationList.results.length > 0 && <>
                            <p>Showing all <FormatNumber value={totalCount} /> Orders</p>
                        </>}
                    </div>
                    )
                }

            </div>
        </div>

    )
}

function Sorter({dir}){
    return (
        <div className='sorter'>
            <i className={`sorter-up fa fa-caret-up ${dir === 'asc' ? 'active' : ''}`}></i>
            <i className={`sorter-dn fa fa-caret-down ${dir === 'desc' ? 'active' : ''}`}></i>
        </div>
    )
}