import React from 'react'

import './PrincipalMetadata.scss'
import ExternalLink from "./ExternalLink";

function nameLine(person){
    return [
        person.first_name, 
        person.middle_name, 
        person.last_name, 
        person.suffix].filter(Boolean).join(' ')
}

function addressLine(loc){
    const street = [loc.addr1, loc.addr2].filter(Boolean).join(' ')
    return <>
        {street && street + ', '} 
        {loc.city && loc.city + ', '} 
        {loc.state && loc.state + ' '} 
        {loc.zip5 && loc.zip5} 
        </>
}

function phoneLine(phone) {
    if (phone) {
        let bare_phone = phone.replaceAll(/[^0-9]/g, '');
        return (bare_phone.length === 10) ? "(" + bare_phone.slice(0,3) + ") " + bare_phone.slice(3,6) + "-" + bare_phone.slice(6) : phone;
    }
}

function queryLine(person){
    return [
        person.first_name,
        person.middle_name,
        person.last_name,
        person.suffix,
        person.city,
        person.state].filter(Boolean).join('+')
}

export default function PrincipalMetadata({principal, link=false}){
    const name = nameLine(principal)

    return (
        <span className="principal-metadata">
            <span>
                { link ? <ExternalLink href={`https://www.google.com/search?q=${queryLine(principal)}`}>{name}</ExternalLink> : name }
                &nbsp;
                {principal.principal_type && <>({principal.principal_type})</>}
            </span><br />
            { principal.addr1 && <>{addressLine(principal)}<br /></> }
            { principal.phone && <>{phoneLine(principal.phone)}<br /></> }
            { principal.fax && <>{phoneLine(principal.fax)}<br /></> }
            { principal.email && <>{principal.email}<br /></> }
        </span>
    )
}
