import React, {useContext, useState, useEffect, Fragment } from 'react'
import { useHistory} from 'react-router-dom'
import useApi from '../../hooks/useApi'
import SessionContext from "../../SessionContext";
import ErrorsContext from "ErrorsContext";
import toast from 'react-hot-toast'

import './MerchantRequest.scss'



export default function MerchantRequest({inquiry, closeMerchantRequestModal}){

    let lastrequest
    const history = useHistory()
    const session = useContext(SessionContext)
    const defaultrequest = {
        business_name: inquiry.business_name,
        business_address: inquiry.business_address, business_state: inquiry.business_state, business_city: inquiry.business_city,
        created_by: session.user.email, business_phone: '', inquiry_id: inquiry.inquiry_id
    }
    const emptyrequest = {
        business_name: '',
        business_address: '', business_state: '', business_city: '',
        created_by: session.user.email, business_phone: '', inquiry_id: ''
    }
    const [ request, setrequest ] = useState(lastrequest || defaultrequest)
    const { apiPost, apiGet } = useApi()
    const { setError } = useContext(ErrorsContext)
    const [ matching, setMatching ] = useState(false)
    const [ matches, setMatches ] = useState([{}])
    const [sendingRequest, setSendingRequest] = useState(false)


    function backToSearch(){
        history.push('/onboarding')
    }


    function setrequestValue(key){
        return (ev) => {
            setrequest({
                ...request,
                [key]: ev.target.value
            })
        }
    }

    useEffect(()=>{
        console.log(sendingRequest)
    },[sendingRequest])
    


    async function handleSubmit(ev){
        ev.preventDefault()
        let arr = await checkRequest()

        if(arr.length === 0){
            sendRequest(ev)
        }
        else{
            setMatching(true)
        }
        
    }

    async function checkRequest(){

        const isBusinessFormValid = (request.business_name && request.business_state && request.business_address && request.business_city);
        let arr =[]
        if (isBusinessFormValid) {
            setError(null)
            await apiPost('merchant/request_check', request)
                .then(res => {
                    if(res){
                        setMatches(res)
                        arr = res
                    }
                })
        }
        else {
            const errorContent = (
                <Fragment>
                    The following details are required to add a new Merchant:<br /><br />
                        <ul style={{ textAlign: "left" }}>
                            <li>Business Name</li>
                            <li>Business Address</li>
                            <li>Business City</li>
                            <li>Business State</li>
                        </ul>
                </Fragment>
            );

            setError({ userMessage: errorContent })
            closeMerchantRequestModal()

        }
        return arr
    }

    function sendRequest(ev){
        const isBusinessFormValid = (request.business_name && request.business_state && request.business_address && request.business_city);
        ev.preventDefault()
        if (isBusinessFormValid) {
            setSendingRequest(true)
            let temp_request = { ...request}
            // remove inquiry id if any of the initial inquiry details have been modified (not just added from empty)
            if ((inquiry.business_name !== "" && inquiry.business_name !== request.business_name) || 
                (inquiry.business_address !== "" && inquiry.business_address !== request.business_address) ||
                (inquiry.business_city !== "" && inquiry.business_city !== request.business_city) || 
                (inquiry.business_state !== "" && inquiry.business_state !== request.business_state)) {
                    temp_request.inquiry_id = ""
                }
            apiPost('merchant/request', temp_request)
            .then(res=>{
                toast("Merchant Requested Successfully")
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                  );
                setrequest(emptyrequest)
            }).finally(() => {
                setMatches([{}])
                setMatching(false)
                setSendingRequest(false)
                closeMerchantRequestModal()
            });
        } else {
            const errorContent = (
                <Fragment>
                    The following details are required to add a new Merchant:<br /><br />
                        <ul style={{ textAlign: "left" }}>
                            <li>Business Name</li>
                            <li>Business Address</li>
                            <li>Business City</li>
                            <li>Business State</li>
                        </ul>
                </Fragment>
            );

            setError({ userMessage: errorContent })
            closeMerchantRequestModal()
        }
    }

    function handleReset(ev){
        ev.preventDefault()
        setrequest(defaultrequest)
        setMatches([{}])
        setMatching(false)
    }

    function DisplayMatches(){
        return (
            matches.map((e) =>
            <>
                <li key={e.id}><strong>Business Name:</strong>&ensp;{e.business_name}&emsp;
                <strong>Address:</strong>&ensp;{e.business_address}&emsp;
                <strong>City:</strong>&ensp;{e.business_city}&emsp;
                <strong>State:</strong>&ensp;{e.business_state}&emsp;
                <strong>Phone:</strong>&ensp;{e.business_phone}&emsp;
                <strong>Requested On:</strong>&ensp;{e.created_on}</li>
            </>    
                )
        )
    }


    return(
            <div>
                {(!matching) ?
                <>
                <div className='button-container'>
                    <button onClick={backToSearch}>Back to Search</button>
                    <button onClick={closeMerchantRequestModal}>Back to Results</button>
                </div>
                <form className='request-merchant-form' onSubmit={handleSubmit} onReset={handleReset}>
                    <h1>Request New Merchant</h1>
                    <div className="field field-business-name">
                        <label htmlFor="business_name">Business Name</label>
                        <input type="text" id="business_name" autoFocus
                            value={request.business_name} onChange={setrequestValue('business_name')}
                        />
                    </div>           
                    <div className="field field-business-address">
                        <label htmlFor="business_address">Street Address</label>
                        <input type="text" id="business_address"
                            value={request.business_address} onChange={setrequestValue('business_address')}
                        />
                    </div>
                    <div className="field field-business-city">
                        <label htmlFor="business_city">City</label>
                        <input type="text" id="business_city"
                            value={request.business_city} onChange={setrequestValue('business_city')}
                        />
                    </div>
                    <div className="field field-business-state">
                        <label htmlFor="business_state">State</label>
                        <input type="text" id="business_state"
                            value={request.business_state} onChange={setrequestValue('business_state')}
                        />
                    </div>
                    <footer>
                        <button type="submit" className="primary" disabled={sendingRequest}>
                            Request Business
                        </button>
                    </footer>
                </form>
            </>
            : 
            <>
                 <div className='button-container'>
                    <button onClick={backToSearch}>Back to Search</button>
                    <button onClick={closeMerchantRequestModal}>Back to Results</button>
                </div>
                <form onSubmit={sendRequest}
                onReset={handleReset}>
                    <h1>Matching Requests Found. Continue?</h1>
                    <div>
                    <ol className='gradient-list'>
                        <DisplayMatches/>
                    </ol>
                    </div>       
                <footer>
                    <button type="submit" className="primary">
                        Request
                    </button>
                    <button type="reset">Cancel</button>
                </footer>
                </form>
            </>}
                </div>
            )
    

}